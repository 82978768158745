import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserDataService } from '../../store/user-data.service';
import { DailyUserData } from '../../store/user-data.model';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { format, subtractMonths } from 'src/app/core/utils/date.utils';

@Component({
  selector: 'app-insert-data-form',
  templateUrl: './insert-data-form.component.html',
  styleUrl: './insert-data-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertDataFormComponent implements OnInit, OnDestroy {
  datesWithData: Date[] = [];
  datesWithData$: Observable<Date[]> = this.userDataService.datesWithData$;

  loadingDatesWithData: boolean;
  loadingDatesWithData$: Observable<boolean> = this.userDataService.loadingDatesWithData$;

  step = 0;

  private unsubscribe$ = new Subject<void>();

  dataForm = new FormGroup({
    dateForm: new FormGroup({
      selectedDate: new FormControl(new Date(), Validators.required)
    }),
    stepsForm: new FormGroup({
      steps: new FormControl<number>(undefined, Validators.required)
    }),
    trainingForm: new FormGroup({
      trained: new FormControl<boolean>(false, Validators.required)
    }),
    weightForm: new FormGroup({
      weight: new FormControl<number>(undefined, Validators.required)
    }),
    nutritionForm: new FormGroup({
      cheatDay: new FormControl(false, Validators.required),
      carbohydrates: new FormControl<number>(undefined, Validators.required),
      proteins: new FormControl<number>(undefined, Validators.required),
      fats: new FormControl<number>(undefined, Validators.required),
      calories: new FormControl<number>(undefined, Validators.required)
    })
  });

  constructor(
    private userDataService: UserDataService,
    private cdr: ChangeDetectorRef
  ) {}

  updateFormData(data: DailyUserData): void {
    this.dataForm.controls.stepsForm.controls.steps.setValue(data.steps);
    this.dataForm.controls.trainingForm.controls.trained.setValue(data.trained);
    this.dataForm.controls.weightForm.controls.weight.setValue(data.weight);
    this.dataForm.controls.nutritionForm.controls.cheatDay.setValue(data.nutrition?.cheatDay ?? false);
    this.dataForm.controls.nutritionForm.controls.carbohydrates.setValue(data.nutrition?.carbohydrates);
    this.dataForm.controls.nutritionForm.controls.proteins.setValue(data.nutrition?.proteins);
    this.dataForm.controls.nutritionForm.controls.fats.setValue(data.nutrition?.fats);
    this.dataForm.controls.nutritionForm.controls.calories.setValue(data.nutrition?.calories);
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.dataForm.controls.nutritionForm.controls.cheatDay.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isCheatDay: boolean) => {
        if (isCheatDay) {
          this.dataForm.controls.nutritionForm.controls.carbohydrates.clearValidators();
          this.dataForm.controls.nutritionForm.controls.carbohydrates.disable();
          this.dataForm.controls.nutritionForm.controls.proteins.clearValidators();
          this.dataForm.controls.nutritionForm.controls.proteins.disable();
          this.dataForm.controls.nutritionForm.controls.fats.clearValidators();
          this.dataForm.controls.nutritionForm.controls.fats.disable();
          this.dataForm.controls.nutritionForm.controls.calories.clearValidators();
          this.dataForm.controls.nutritionForm.controls.calories.disable();
        } else {
          this.dataForm.controls.nutritionForm.controls.carbohydrates.setValidators([Validators.required]);
          this.dataForm.controls.nutritionForm.controls.carbohydrates.enable();
          this.dataForm.controls.nutritionForm.controls.proteins.setValidators([Validators.required]);
          this.dataForm.controls.nutritionForm.controls.proteins.enable();
          this.dataForm.controls.nutritionForm.controls.fats.setValidators([Validators.required]);
          this.dataForm.controls.nutritionForm.controls.fats.enable();
          this.dataForm.controls.nutritionForm.controls.calories.setValidators([Validators.required]);
          this.dataForm.controls.nutritionForm.controls.calories.enable();
        }
        this.dataForm.controls.nutritionForm.controls.carbohydrates.updateValueAndValidity();
        this.dataForm.controls.nutritionForm.controls.proteins.updateValueAndValidity();
        this.dataForm.controls.nutritionForm.controls.fats.updateValueAndValidity();
        this.dataForm.controls.nutritionForm.controls.calories.updateValueAndValidity();
      });

    this.userDataService.datesWithData$.pipe(takeUntil(this.unsubscribe$)).subscribe((d) => {
      this.datesWithData = d;
    });

    this.userDataService.loadingDatesWithData$.pipe(takeUntil(this.unsubscribe$)).subscribe((d) => (this.loadingDatesWithData = d));

    combineLatest([this.userDataService.dataByDate$, this.userDataService.loadingDataByDate$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([data, loading]) => {
        if (loading === false && data != undefined) {
          this.updateFormData(data);
        }
      });

    const date = subtractMonths(new Date(), 1);

    this.userDataService.loadDatesWithData(date.getFullYear(), date.getMonth());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (!this.loadingDatesWithData && this.datesWithData) {
      if (view === 'month') {
        return this.datesWithData.find((d) => format(d) === format(cellDate)) ? 'busy-date' : '';
      }
    }

    return '';
  };

  updateFormDate(value: Date) {
    this.dataForm.controls.dateForm.controls.selectedDate.setValue(value);
    this.userDataService.loadDataByDate(value);
  }

  dateCanBeSelected(date: Date) {
    if (this.loadingDatesWithData) {
      return false;
    }
    const now = new Date();
    return date <= now && date >= new Date(now.getFullYear(), now.getMonth() - 1, 1);
  }

  save() {
    if (this.dataForm.valid) {
      const isCheatDay = this.dataForm.controls.nutritionForm.controls.cheatDay.value;

      const data: DailyUserData = {
        date: format(this.dataForm.controls.dateForm.controls.selectedDate.value),
        steps: this.dataForm.controls.stepsForm.controls.steps.value,
        trained: this.dataForm.controls.trainingForm.controls.trained.value,
        weight: this.dataForm.controls.weightForm.controls.weight.value,
        nutrition: {
          cheatDay: isCheatDay,
          carbohydrates: !isCheatDay ? this.dataForm.controls.nutritionForm.controls.carbohydrates.value : null,
          proteins: !isCheatDay ? this.dataForm.controls.nutritionForm.controls.proteins.value : null,
          fats: !isCheatDay ? this.dataForm.controls.nutritionForm.controls.fats.value : null,
          calories: !isCheatDay ? this.dataForm.controls.nutritionForm.controls.calories.value : null
        }
      };

      this.userDataService.save(data);
    }
  }
}
